ace.config.setModuleLoader("ace/ext/beautify", () => import("./src-noconflict/ext-beautify.js"));
ace.config.setModuleLoader("ace/ext/code_lens", () => import("./src-noconflict/ext-code_lens.js"));
ace.config.setModuleLoader("ace/ext/command_bar", () => import("./src-noconflict/ext-command_bar.js"));
ace.config.setModuleLoader("ace/ext/elastic_tabstops_lite", () => import("./src-noconflict/ext-elastic_tabstops_lite.js"));
ace.config.setModuleLoader("ace/ext/emmet", () => import("./src-noconflict/ext-emmet.js"));
ace.config.setModuleLoader("ace/ext/error_marker", () => import("./src-noconflict/ext-error_marker.js"));
ace.config.setModuleLoader("ace/ext/hardwrap", () => import("./src-noconflict/ext-hardwrap.js"));
ace.config.setModuleLoader("ace/ext/inline_autocomplete", () => import("./src-noconflict/ext-inline_autocomplete.js"));
ace.config.setModuleLoader("ace/ext/keyboard_menu", () => import("./src-noconflict/ext-keybinding_menu.js"));
ace.config.setModuleLoader("ace/ext/language_tools", () => import("./src-noconflict/ext-language_tools.js"));
ace.config.setModuleLoader("ace/ext/linking", () => import("./src-noconflict/ext-linking.js"));
ace.config.setModuleLoader("ace/ext/modelist", () => import("./src-noconflict/ext-modelist.js"));
ace.config.setModuleLoader("ace/ext/options", () => import("./src-noconflict/ext-options.js"));
ace.config.setModuleLoader("ace/ext/prompt", () => import("./src-noconflict/ext-prompt.js"));
ace.config.setModuleLoader("ace/ext/rtl", () => import("./src-noconflict/ext-rtl.js"));
ace.config.setModuleLoader("ace/ext/searchbox", () => import("./src-noconflict/ext-searchbox.js"));
ace.config.setModuleLoader("ace/ext/settings_menu", () => import("./src-noconflict/ext-settings_menu.js"));
ace.config.setModuleLoader("ace/ext/simple_tokenizer", () => import("./src-noconflict/ext-simple_tokenizer.js"));
ace.config.setModuleLoader("ace/ext/spellcheck", () => import("./src-noconflict/ext-spellcheck.js"));
ace.config.setModuleLoader("ace/ext/split", () => import("./src-noconflict/ext-split.js"));
ace.config.setModuleLoader("ace/ext/static_highlight", () => import("./src-noconflict/ext-static_highlight.js"));
ace.config.setModuleLoader("ace/ext/statusbar", () => import("./src-noconflict/ext-statusbar.js"));
ace.config.setModuleLoader("ace/ext/textarea", () => import("./src-noconflict/ext-textarea.js"));
ace.config.setModuleLoader("ace/ext/themelist", () => import("./src-noconflict/ext-themelist.js"));
ace.config.setModuleLoader("ace/ext/whitespace", () => import("./src-noconflict/ext-whitespace.js"));
ace.config.setModuleLoader("ace/keyboard/emacs", () => import("./src-noconflict/keybinding-emacs.js"));
ace.config.setModuleLoader("ace/keyboard/sublime", () => import("./src-noconflict/keybinding-sublime.js"));
ace.config.setModuleLoader("ace/keyboard/vim", () => import("./src-noconflict/keybinding-vim.js"));
ace.config.setModuleLoader("ace/keyboard/vscode", () => import("./src-noconflict/keybinding-vscode.js"));
ace.config.setModuleLoader("ace/mode/abap", () => import("./src-noconflict/mode-abap.js"));
ace.config.setModuleLoader("ace/mode/abc", () => import("./src-noconflict/mode-abc.js"));
ace.config.setModuleLoader("ace/mode/actionscript", () => import("./src-noconflict/mode-actionscript.js"));
ace.config.setModuleLoader("ace/mode/ada", () => import("./src-noconflict/mode-ada.js"));
ace.config.setModuleLoader("ace/mode/alda", () => import("./src-noconflict/mode-alda.js"));
ace.config.setModuleLoader("ace/mode/apache_conf", () => import("./src-noconflict/mode-apache_conf.js"));
ace.config.setModuleLoader("ace/mode/apex", () => import("./src-noconflict/mode-apex.js"));
ace.config.setModuleLoader("ace/mode/applescript", () => import("./src-noconflict/mode-applescript.js"));
ace.config.setModuleLoader("ace/mode/aql", () => import("./src-noconflict/mode-aql.js"));
ace.config.setModuleLoader("ace/mode/asciidoc", () => import("./src-noconflict/mode-asciidoc.js"));
ace.config.setModuleLoader("ace/mode/asl", () => import("./src-noconflict/mode-asl.js"));
ace.config.setModuleLoader("ace/mode/assembly_x86", () => import("./src-noconflict/mode-assembly_x86.js"));
ace.config.setModuleLoader("ace/mode/autohotkey", () => import("./src-noconflict/mode-autohotkey.js"));
ace.config.setModuleLoader("ace/mode/batchfile", () => import("./src-noconflict/mode-batchfile.js"));
ace.config.setModuleLoader("ace/mode/bibtex", () => import("./src-noconflict/mode-bibtex.js"));
ace.config.setModuleLoader("ace/mode/c9search", () => import("./src-noconflict/mode-c9search.js"));
ace.config.setModuleLoader("ace/mode/c_cpp", () => import("./src-noconflict/mode-c_cpp.js"));
ace.config.setModuleLoader("ace/mode/cirru", () => import("./src-noconflict/mode-cirru.js"));
ace.config.setModuleLoader("ace/mode/clojure", () => import("./src-noconflict/mode-clojure.js"));
ace.config.setModuleLoader("ace/mode/cobol", () => import("./src-noconflict/mode-cobol.js"));
ace.config.setModuleLoader("ace/mode/coffee", () => import("./src-noconflict/mode-coffee.js"));
ace.config.setModuleLoader("ace/mode/coldfusion", () => import("./src-noconflict/mode-coldfusion.js"));
ace.config.setModuleLoader("ace/mode/crystal", () => import("./src-noconflict/mode-crystal.js"));
ace.config.setModuleLoader("ace/mode/csharp", () => import("./src-noconflict/mode-csharp.js"));
ace.config.setModuleLoader("ace/mode/csound_document", () => import("./src-noconflict/mode-csound_document.js"));
ace.config.setModuleLoader("ace/mode/csound_orchestra", () => import("./src-noconflict/mode-csound_orchestra.js"));
ace.config.setModuleLoader("ace/mode/csound_score", () => import("./src-noconflict/mode-csound_score.js"));
ace.config.setModuleLoader("ace/mode/csp", () => import("./src-noconflict/mode-csp.js"));
ace.config.setModuleLoader("ace/mode/css", () => import("./src-noconflict/mode-css.js"));
ace.config.setModuleLoader("ace/mode/curly", () => import("./src-noconflict/mode-curly.js"));
ace.config.setModuleLoader("ace/mode/cuttlefish", () => import("./src-noconflict/mode-cuttlefish.js"));
ace.config.setModuleLoader("ace/mode/d", () => import("./src-noconflict/mode-d.js"));
ace.config.setModuleLoader("ace/mode/dart", () => import("./src-noconflict/mode-dart.js"));
ace.config.setModuleLoader("ace/mode/diff", () => import("./src-noconflict/mode-diff.js"));
ace.config.setModuleLoader("ace/mode/django", () => import("./src-noconflict/mode-django.js"));
ace.config.setModuleLoader("ace/mode/dockerfile", () => import("./src-noconflict/mode-dockerfile.js"));
ace.config.setModuleLoader("ace/mode/dot", () => import("./src-noconflict/mode-dot.js"));
ace.config.setModuleLoader("ace/mode/drools", () => import("./src-noconflict/mode-drools.js"));
ace.config.setModuleLoader("ace/mode/edifact", () => import("./src-noconflict/mode-edifact.js"));
ace.config.setModuleLoader("ace/mode/eiffel", () => import("./src-noconflict/mode-eiffel.js"));
ace.config.setModuleLoader("ace/mode/ejs", () => import("./src-noconflict/mode-ejs.js"));
ace.config.setModuleLoader("ace/mode/elixir", () => import("./src-noconflict/mode-elixir.js"));
ace.config.setModuleLoader("ace/mode/elm", () => import("./src-noconflict/mode-elm.js"));
ace.config.setModuleLoader("ace/mode/erlang", () => import("./src-noconflict/mode-erlang.js"));
ace.config.setModuleLoader("ace/mode/forth", () => import("./src-noconflict/mode-forth.js"));
ace.config.setModuleLoader("ace/mode/fortran", () => import("./src-noconflict/mode-fortran.js"));
ace.config.setModuleLoader("ace/mode/fsharp", () => import("./src-noconflict/mode-fsharp.js"));
ace.config.setModuleLoader("ace/mode/fsl", () => import("./src-noconflict/mode-fsl.js"));
ace.config.setModuleLoader("ace/mode/ftl", () => import("./src-noconflict/mode-ftl.js"));
ace.config.setModuleLoader("ace/mode/gcode", () => import("./src-noconflict/mode-gcode.js"));
ace.config.setModuleLoader("ace/mode/gherkin", () => import("./src-noconflict/mode-gherkin.js"));
ace.config.setModuleLoader("ace/mode/gitignore", () => import("./src-noconflict/mode-gitignore.js"));
ace.config.setModuleLoader("ace/mode/glsl", () => import("./src-noconflict/mode-glsl.js"));
ace.config.setModuleLoader("ace/mode/gobstones", () => import("./src-noconflict/mode-gobstones.js"));
ace.config.setModuleLoader("ace/mode/golang", () => import("./src-noconflict/mode-golang.js"));
ace.config.setModuleLoader("ace/mode/graphqlschema", () => import("./src-noconflict/mode-graphqlschema.js"));
ace.config.setModuleLoader("ace/mode/groovy", () => import("./src-noconflict/mode-groovy.js"));
ace.config.setModuleLoader("ace/mode/haml", () => import("./src-noconflict/mode-haml.js"));
ace.config.setModuleLoader("ace/mode/handlebars", () => import("./src-noconflict/mode-handlebars.js"));
ace.config.setModuleLoader("ace/mode/haskell", () => import("./src-noconflict/mode-haskell.js"));
ace.config.setModuleLoader("ace/mode/haskell_cabal", () => import("./src-noconflict/mode-haskell_cabal.js"));
ace.config.setModuleLoader("ace/mode/haxe", () => import("./src-noconflict/mode-haxe.js"));
ace.config.setModuleLoader("ace/mode/hjson", () => import("./src-noconflict/mode-hjson.js"));
ace.config.setModuleLoader("ace/mode/html", () => import("./src-noconflict/mode-html.js"));
ace.config.setModuleLoader("ace/mode/html_elixir", () => import("./src-noconflict/mode-html_elixir.js"));
ace.config.setModuleLoader("ace/mode/html_ruby", () => import("./src-noconflict/mode-html_ruby.js"));
ace.config.setModuleLoader("ace/mode/ini", () => import("./src-noconflict/mode-ini.js"));
ace.config.setModuleLoader("ace/mode/io", () => import("./src-noconflict/mode-io.js"));
ace.config.setModuleLoader("ace/mode/ion", () => import("./src-noconflict/mode-ion.js"));
ace.config.setModuleLoader("ace/mode/jack", () => import("./src-noconflict/mode-jack.js"));
ace.config.setModuleLoader("ace/mode/jade", () => import("./src-noconflict/mode-jade.js"));
ace.config.setModuleLoader("ace/mode/java", () => import("./src-noconflict/mode-java.js"));
ace.config.setModuleLoader("ace/mode/javascript", () => import("./src-noconflict/mode-javascript.js"));
ace.config.setModuleLoader("ace/mode/jexl", () => import("./src-noconflict/mode-jexl.js"));
ace.config.setModuleLoader("ace/mode/json", () => import("./src-noconflict/mode-json.js"));
ace.config.setModuleLoader("ace/mode/json5", () => import("./src-noconflict/mode-json5.js"));
ace.config.setModuleLoader("ace/mode/jsoniq", () => import("./src-noconflict/mode-jsoniq.js"));
ace.config.setModuleLoader("ace/mode/jsp", () => import("./src-noconflict/mode-jsp.js"));
ace.config.setModuleLoader("ace/mode/jssm", () => import("./src-noconflict/mode-jssm.js"));
ace.config.setModuleLoader("ace/mode/jsx", () => import("./src-noconflict/mode-jsx.js"));
ace.config.setModuleLoader("ace/mode/julia", () => import("./src-noconflict/mode-julia.js"));
ace.config.setModuleLoader("ace/mode/kotlin", () => import("./src-noconflict/mode-kotlin.js"));
ace.config.setModuleLoader("ace/mode/latex", () => import("./src-noconflict/mode-latex.js"));
ace.config.setModuleLoader("ace/mode/latte", () => import("./src-noconflict/mode-latte.js"));
ace.config.setModuleLoader("ace/mode/less", () => import("./src-noconflict/mode-less.js"));
ace.config.setModuleLoader("ace/mode/liquid", () => import("./src-noconflict/mode-liquid.js"));
ace.config.setModuleLoader("ace/mode/lisp", () => import("./src-noconflict/mode-lisp.js"));
ace.config.setModuleLoader("ace/mode/livescript", () => import("./src-noconflict/mode-livescript.js"));
ace.config.setModuleLoader("ace/mode/logiql", () => import("./src-noconflict/mode-logiql.js"));
ace.config.setModuleLoader("ace/mode/logtalk", () => import("./src-noconflict/mode-logtalk.js"));
ace.config.setModuleLoader("ace/mode/lsl", () => import("./src-noconflict/mode-lsl.js"));
ace.config.setModuleLoader("ace/mode/lua", () => import("./src-noconflict/mode-lua.js"));
ace.config.setModuleLoader("ace/mode/luapage", () => import("./src-noconflict/mode-luapage.js"));
ace.config.setModuleLoader("ace/mode/lucene", () => import("./src-noconflict/mode-lucene.js"));
ace.config.setModuleLoader("ace/mode/makefile", () => import("./src-noconflict/mode-makefile.js"));
ace.config.setModuleLoader("ace/mode/markdown", () => import("./src-noconflict/mode-markdown.js"));
ace.config.setModuleLoader("ace/mode/mask", () => import("./src-noconflict/mode-mask.js"));
ace.config.setModuleLoader("ace/mode/matlab", () => import("./src-noconflict/mode-matlab.js"));
ace.config.setModuleLoader("ace/mode/maze", () => import("./src-noconflict/mode-maze.js"));
ace.config.setModuleLoader("ace/mode/mediawiki", () => import("./src-noconflict/mode-mediawiki.js"));
ace.config.setModuleLoader("ace/mode/mel", () => import("./src-noconflict/mode-mel.js"));
ace.config.setModuleLoader("ace/mode/mips", () => import("./src-noconflict/mode-mips.js"));
ace.config.setModuleLoader("ace/mode/mixal", () => import("./src-noconflict/mode-mixal.js"));
ace.config.setModuleLoader("ace/mode/mushcode", () => import("./src-noconflict/mode-mushcode.js"));
ace.config.setModuleLoader("ace/mode/mysql", () => import("./src-noconflict/mode-mysql.js"));
ace.config.setModuleLoader("ace/mode/nginx", () => import("./src-noconflict/mode-nginx.js"));
ace.config.setModuleLoader("ace/mode/nim", () => import("./src-noconflict/mode-nim.js"));
ace.config.setModuleLoader("ace/mode/nix", () => import("./src-noconflict/mode-nix.js"));
ace.config.setModuleLoader("ace/mode/nsis", () => import("./src-noconflict/mode-nsis.js"));
ace.config.setModuleLoader("ace/mode/nunjucks", () => import("./src-noconflict/mode-nunjucks.js"));
ace.config.setModuleLoader("ace/mode/objectivec", () => import("./src-noconflict/mode-objectivec.js"));
ace.config.setModuleLoader("ace/mode/ocaml", () => import("./src-noconflict/mode-ocaml.js"));
ace.config.setModuleLoader("ace/mode/odin", () => import("./src-noconflict/mode-odin.js"));
ace.config.setModuleLoader("ace/mode/partiql", () => import("./src-noconflict/mode-partiql.js"));
ace.config.setModuleLoader("ace/mode/pascal", () => import("./src-noconflict/mode-pascal.js"));
ace.config.setModuleLoader("ace/mode/perl", () => import("./src-noconflict/mode-perl.js"));
ace.config.setModuleLoader("ace/mode/pgsql", () => import("./src-noconflict/mode-pgsql.js"));
ace.config.setModuleLoader("ace/mode/php", () => import("./src-noconflict/mode-php.js"));
ace.config.setModuleLoader("ace/mode/php_laravel_blade", () => import("./src-noconflict/mode-php_laravel_blade.js"));
ace.config.setModuleLoader("ace/mode/pig", () => import("./src-noconflict/mode-pig.js"));
ace.config.setModuleLoader("ace/mode/plain_text", () => import("./src-noconflict/mode-plain_text.js"));
ace.config.setModuleLoader("ace/mode/plsql", () => import("./src-noconflict/mode-plsql.js"));
ace.config.setModuleLoader("ace/mode/powershell", () => import("./src-noconflict/mode-powershell.js"));
ace.config.setModuleLoader("ace/mode/praat", () => import("./src-noconflict/mode-praat.js"));
ace.config.setModuleLoader("ace/mode/prisma", () => import("./src-noconflict/mode-prisma.js"));
ace.config.setModuleLoader("ace/mode/prolog", () => import("./src-noconflict/mode-prolog.js"));
ace.config.setModuleLoader("ace/mode/properties", () => import("./src-noconflict/mode-properties.js"));
ace.config.setModuleLoader("ace/mode/protobuf", () => import("./src-noconflict/mode-protobuf.js"));
ace.config.setModuleLoader("ace/mode/prql", () => import("./src-noconflict/mode-prql.js"));
ace.config.setModuleLoader("ace/mode/puppet", () => import("./src-noconflict/mode-puppet.js"));
ace.config.setModuleLoader("ace/mode/python", () => import("./src-noconflict/mode-python.js"));
ace.config.setModuleLoader("ace/mode/qml", () => import("./src-noconflict/mode-qml.js"));
ace.config.setModuleLoader("ace/mode/r", () => import("./src-noconflict/mode-r.js"));
ace.config.setModuleLoader("ace/mode/raku", () => import("./src-noconflict/mode-raku.js"));
ace.config.setModuleLoader("ace/mode/razor", () => import("./src-noconflict/mode-razor.js"));
ace.config.setModuleLoader("ace/mode/rdoc", () => import("./src-noconflict/mode-rdoc.js"));
ace.config.setModuleLoader("ace/mode/red", () => import("./src-noconflict/mode-red.js"));
ace.config.setModuleLoader("ace/mode/redshift", () => import("./src-noconflict/mode-redshift.js"));
ace.config.setModuleLoader("ace/mode/rhtml", () => import("./src-noconflict/mode-rhtml.js"));
ace.config.setModuleLoader("ace/mode/robot", () => import("./src-noconflict/mode-robot.js"));
ace.config.setModuleLoader("ace/mode/rst", () => import("./src-noconflict/mode-rst.js"));
ace.config.setModuleLoader("ace/mode/ruby", () => import("./src-noconflict/mode-ruby.js"));
ace.config.setModuleLoader("ace/mode/rust", () => import("./src-noconflict/mode-rust.js"));
ace.config.setModuleLoader("ace/mode/sac", () => import("./src-noconflict/mode-sac.js"));
ace.config.setModuleLoader("ace/mode/sass", () => import("./src-noconflict/mode-sass.js"));
ace.config.setModuleLoader("ace/mode/scad", () => import("./src-noconflict/mode-scad.js"));
ace.config.setModuleLoader("ace/mode/scala", () => import("./src-noconflict/mode-scala.js"));
ace.config.setModuleLoader("ace/mode/scheme", () => import("./src-noconflict/mode-scheme.js"));
ace.config.setModuleLoader("ace/mode/scrypt", () => import("./src-noconflict/mode-scrypt.js"));
ace.config.setModuleLoader("ace/mode/scss", () => import("./src-noconflict/mode-scss.js"));
ace.config.setModuleLoader("ace/mode/sh", () => import("./src-noconflict/mode-sh.js"));
ace.config.setModuleLoader("ace/mode/sjs", () => import("./src-noconflict/mode-sjs.js"));
ace.config.setModuleLoader("ace/mode/slim", () => import("./src-noconflict/mode-slim.js"));
ace.config.setModuleLoader("ace/mode/smarty", () => import("./src-noconflict/mode-smarty.js"));
ace.config.setModuleLoader("ace/mode/smithy", () => import("./src-noconflict/mode-smithy.js"));
ace.config.setModuleLoader("ace/mode/snippets", () => import("./src-noconflict/mode-snippets.js"));
ace.config.setModuleLoader("ace/mode/soy_template", () => import("./src-noconflict/mode-soy_template.js"));
ace.config.setModuleLoader("ace/mode/space", () => import("./src-noconflict/mode-space.js"));
ace.config.setModuleLoader("ace/mode/sparql", () => import("./src-noconflict/mode-sparql.js"));
ace.config.setModuleLoader("ace/mode/sql", () => import("./src-noconflict/mode-sql.js"));
ace.config.setModuleLoader("ace/mode/sqlserver", () => import("./src-noconflict/mode-sqlserver.js"));
ace.config.setModuleLoader("ace/mode/stylus", () => import("./src-noconflict/mode-stylus.js"));
ace.config.setModuleLoader("ace/mode/svg", () => import("./src-noconflict/mode-svg.js"));
ace.config.setModuleLoader("ace/mode/swift", () => import("./src-noconflict/mode-swift.js"));
ace.config.setModuleLoader("ace/mode/tcl", () => import("./src-noconflict/mode-tcl.js"));
ace.config.setModuleLoader("ace/mode/terraform", () => import("./src-noconflict/mode-terraform.js"));
ace.config.setModuleLoader("ace/mode/tex", () => import("./src-noconflict/mode-tex.js"));
ace.config.setModuleLoader("ace/mode/text", () => import("./src-noconflict/mode-text.js"));
ace.config.setModuleLoader("ace/mode/textile", () => import("./src-noconflict/mode-textile.js"));
ace.config.setModuleLoader("ace/mode/toml", () => import("./src-noconflict/mode-toml.js"));
ace.config.setModuleLoader("ace/mode/tsx", () => import("./src-noconflict/mode-tsx.js"));
ace.config.setModuleLoader("ace/mode/turtle", () => import("./src-noconflict/mode-turtle.js"));
ace.config.setModuleLoader("ace/mode/twig", () => import("./src-noconflict/mode-twig.js"));
ace.config.setModuleLoader("ace/mode/typescript", () => import("./src-noconflict/mode-typescript.js"));
ace.config.setModuleLoader("ace/mode/vala", () => import("./src-noconflict/mode-vala.js"));
ace.config.setModuleLoader("ace/mode/vbscript", () => import("./src-noconflict/mode-vbscript.js"));
ace.config.setModuleLoader("ace/mode/velocity", () => import("./src-noconflict/mode-velocity.js"));
ace.config.setModuleLoader("ace/mode/verilog", () => import("./src-noconflict/mode-verilog.js"));
ace.config.setModuleLoader("ace/mode/vhdl", () => import("./src-noconflict/mode-vhdl.js"));
ace.config.setModuleLoader("ace/mode/visualforce", () => import("./src-noconflict/mode-visualforce.js"));
ace.config.setModuleLoader("ace/mode/wollok", () => import("./src-noconflict/mode-wollok.js"));
ace.config.setModuleLoader("ace/mode/xml", () => import("./src-noconflict/mode-xml.js"));
ace.config.setModuleLoader("ace/mode/xquery", () => import("./src-noconflict/mode-xquery.js"));
ace.config.setModuleLoader("ace/mode/yaml", () => import("./src-noconflict/mode-yaml.js"));
ace.config.setModuleLoader("ace/mode/zeek", () => import("./src-noconflict/mode-zeek.js"));
ace.config.setModuleLoader("ace/theme/ambiance", () => import("./src-noconflict/theme-ambiance.js"));
ace.config.setModuleLoader("ace/theme/chaos", () => import("./src-noconflict/theme-chaos.js"));
ace.config.setModuleLoader("ace/theme/chrome", () => import("./src-noconflict/theme-chrome.js"));
ace.config.setModuleLoader("ace/theme/cloud9_day", () => import("./src-noconflict/theme-cloud9_day.js"));
ace.config.setModuleLoader("ace/theme/cloud9_night", () => import("./src-noconflict/theme-cloud9_night.js"));
ace.config.setModuleLoader("ace/theme/cloud9_night_low_color", () => import("./src-noconflict/theme-cloud9_night_low_color.js"));
ace.config.setModuleLoader("ace/theme/clouds", () => import("./src-noconflict/theme-clouds.js"));
ace.config.setModuleLoader("ace/theme/clouds_midnight", () => import("./src-noconflict/theme-clouds_midnight.js"));
ace.config.setModuleLoader("ace/theme/cobalt", () => import("./src-noconflict/theme-cobalt.js"));
ace.config.setModuleLoader("ace/theme/crimson_editor", () => import("./src-noconflict/theme-crimson_editor.js"));
ace.config.setModuleLoader("ace/theme/dawn", () => import("./src-noconflict/theme-dawn.js"));
ace.config.setModuleLoader("ace/theme/dracula", () => import("./src-noconflict/theme-dracula.js"));
ace.config.setModuleLoader("ace/theme/dreamweaver", () => import("./src-noconflict/theme-dreamweaver.js"));
ace.config.setModuleLoader("ace/theme/eclipse", () => import("./src-noconflict/theme-eclipse.js"));
ace.config.setModuleLoader("ace/theme/github", () => import("./src-noconflict/theme-github.js"));
ace.config.setModuleLoader("ace/theme/github_dark", () => import("./src-noconflict/theme-github_dark.js"));
ace.config.setModuleLoader("ace/theme/gob", () => import("./src-noconflict/theme-gob.js"));
ace.config.setModuleLoader("ace/theme/gruvbox", () => import("./src-noconflict/theme-gruvbox.js"));
ace.config.setModuleLoader("ace/theme/gruvbox_dark_hard", () => import("./src-noconflict/theme-gruvbox_dark_hard.js"));
ace.config.setModuleLoader("ace/theme/gruvbox_light_hard", () => import("./src-noconflict/theme-gruvbox_light_hard.js"));
ace.config.setModuleLoader("ace/theme/idle_fingers", () => import("./src-noconflict/theme-idle_fingers.js"));
ace.config.setModuleLoader("ace/theme/iplastic", () => import("./src-noconflict/theme-iplastic.js"));
ace.config.setModuleLoader("ace/theme/katzenmilch", () => import("./src-noconflict/theme-katzenmilch.js"));
ace.config.setModuleLoader("ace/theme/kr_theme", () => import("./src-noconflict/theme-kr_theme.js"));
ace.config.setModuleLoader("ace/theme/kuroir", () => import("./src-noconflict/theme-kuroir.js"));
ace.config.setModuleLoader("ace/theme/merbivore", () => import("./src-noconflict/theme-merbivore.js"));
ace.config.setModuleLoader("ace/theme/merbivore_soft", () => import("./src-noconflict/theme-merbivore_soft.js"));
ace.config.setModuleLoader("ace/theme/mono_industrial", () => import("./src-noconflict/theme-mono_industrial.js"));
ace.config.setModuleLoader("ace/theme/monokai", () => import("./src-noconflict/theme-monokai.js"));
ace.config.setModuleLoader("ace/theme/nord_dark", () => import("./src-noconflict/theme-nord_dark.js"));
ace.config.setModuleLoader("ace/theme/one_dark", () => import("./src-noconflict/theme-one_dark.js"));
ace.config.setModuleLoader("ace/theme/pastel_on_dark", () => import("./src-noconflict/theme-pastel_on_dark.js"));
ace.config.setModuleLoader("ace/theme/solarized_dark", () => import("./src-noconflict/theme-solarized_dark.js"));
ace.config.setModuleLoader("ace/theme/solarized_light", () => import("./src-noconflict/theme-solarized_light.js"));
ace.config.setModuleLoader("ace/theme/sqlserver", () => import("./src-noconflict/theme-sqlserver.js"));
ace.config.setModuleLoader("ace/theme/terminal", () => import("./src-noconflict/theme-terminal.js"));
ace.config.setModuleLoader("ace/theme/textmate", () => import("./src-noconflict/theme-textmate.js"));
ace.config.setModuleLoader("ace/theme/tomorrow", () => import("./src-noconflict/theme-tomorrow.js"));
ace.config.setModuleLoader("ace/theme/tomorrow_night", () => import("./src-noconflict/theme-tomorrow_night.js"));
ace.config.setModuleLoader("ace/theme/tomorrow_night_blue", () => import("./src-noconflict/theme-tomorrow_night_blue.js"));
ace.config.setModuleLoader("ace/theme/tomorrow_night_bright", () => import("./src-noconflict/theme-tomorrow_night_bright.js"));
ace.config.setModuleLoader("ace/theme/tomorrow_night_eighties", () => import("./src-noconflict/theme-tomorrow_night_eighties.js"));
ace.config.setModuleLoader("ace/theme/twilight", () => import("./src-noconflict/theme-twilight.js"));
ace.config.setModuleLoader("ace/theme/vibrant_ink", () => import("./src-noconflict/theme-vibrant_ink.js"));
ace.config.setModuleLoader("ace/theme/xcode", () => import("./src-noconflict/theme-xcode.js"));
ace.config.setModuleLoader("ace/mode/base_worker", () => import("./src-noconflict/worker-base.js"));
ace.config.setModuleLoader("ace/mode/coffee_worker", () => import("./src-noconflict/worker-coffee.js"));
ace.config.setModuleLoader("ace/mode/css_worker", () => import("./src-noconflict/worker-css.js"));
ace.config.setModuleLoader("ace/mode/html_worker", () => import("./src-noconflict/worker-html.js"));
ace.config.setModuleLoader("ace/mode/javascript_worker", () => import("./src-noconflict/worker-javascript.js"));
ace.config.setModuleLoader("ace/mode/json_worker", () => import("./src-noconflict/worker-json.js"));
ace.config.setModuleLoader("ace/mode/lua_worker", () => import("./src-noconflict/worker-lua.js"));
ace.config.setModuleLoader("ace/mode/php_worker", () => import("./src-noconflict/worker-php.js"));
ace.config.setModuleLoader("ace/mode/xml_worker", () => import("./src-noconflict/worker-xml.js"));
ace.config.setModuleLoader("ace/mode/xquery_worker", () => import("./src-noconflict/worker-xquery.js"));
ace.config.setModuleLoader("ace/mode/yaml_worker", () => import("./src-noconflict/worker-yaml.js"));
ace.config.setModuleLoader("ace/snippets/abap", () => import("./src-noconflict/snippets/abap.js"));
ace.config.setModuleLoader("ace/snippets/abc", () => import("./src-noconflict/snippets/abc.js"));
ace.config.setModuleLoader("ace/snippets/actionscript", () => import("./src-noconflict/snippets/actionscript.js"));
ace.config.setModuleLoader("ace/snippets/ada", () => import("./src-noconflict/snippets/ada.js"));
ace.config.setModuleLoader("ace/snippets/alda", () => import("./src-noconflict/snippets/alda.js"));
ace.config.setModuleLoader("ace/snippets/apache_conf", () => import("./src-noconflict/snippets/apache_conf.js"));
ace.config.setModuleLoader("ace/snippets/apex", () => import("./src-noconflict/snippets/apex.js"));
ace.config.setModuleLoader("ace/snippets/applescript", () => import("./src-noconflict/snippets/applescript.js"));
ace.config.setModuleLoader("ace/snippets/aql", () => import("./src-noconflict/snippets/aql.js"));
ace.config.setModuleLoader("ace/snippets/asciidoc", () => import("./src-noconflict/snippets/asciidoc.js"));
ace.config.setModuleLoader("ace/snippets/asl", () => import("./src-noconflict/snippets/asl.js"));
ace.config.setModuleLoader("ace/snippets/assembly_x86", () => import("./src-noconflict/snippets/assembly_x86.js"));
ace.config.setModuleLoader("ace/snippets/autohotkey", () => import("./src-noconflict/snippets/autohotkey.js"));
ace.config.setModuleLoader("ace/snippets/batchfile", () => import("./src-noconflict/snippets/batchfile.js"));
ace.config.setModuleLoader("ace/snippets/bibtex", () => import("./src-noconflict/snippets/bibtex.js"));
ace.config.setModuleLoader("ace/snippets/c9search", () => import("./src-noconflict/snippets/c9search.js"));
ace.config.setModuleLoader("ace/snippets/c_cpp", () => import("./src-noconflict/snippets/c_cpp.js"));
ace.config.setModuleLoader("ace/snippets/cirru", () => import("./src-noconflict/snippets/cirru.js"));
ace.config.setModuleLoader("ace/snippets/clojure", () => import("./src-noconflict/snippets/clojure.js"));
ace.config.setModuleLoader("ace/snippets/cobol", () => import("./src-noconflict/snippets/cobol.js"));
ace.config.setModuleLoader("ace/snippets/coffee", () => import("./src-noconflict/snippets/coffee.js"));
ace.config.setModuleLoader("ace/snippets/coldfusion", () => import("./src-noconflict/snippets/coldfusion.js"));
ace.config.setModuleLoader("ace/snippets/crystal", () => import("./src-noconflict/snippets/crystal.js"));
ace.config.setModuleLoader("ace/snippets/csharp", () => import("./src-noconflict/snippets/csharp.js"));
ace.config.setModuleLoader("ace/snippets/csound_document", () => import("./src-noconflict/snippets/csound_document.js"));
ace.config.setModuleLoader("ace/snippets/csound_orchestra", () => import("./src-noconflict/snippets/csound_orchestra.js"));
ace.config.setModuleLoader("ace/snippets/csound_score", () => import("./src-noconflict/snippets/csound_score.js"));
ace.config.setModuleLoader("ace/snippets/csp", () => import("./src-noconflict/snippets/csp.js"));
ace.config.setModuleLoader("ace/snippets/css", () => import("./src-noconflict/snippets/css.js"));
ace.config.setModuleLoader("ace/snippets/curly", () => import("./src-noconflict/snippets/curly.js"));
ace.config.setModuleLoader("ace/snippets/cuttlefish", () => import("./src-noconflict/snippets/cuttlefish.js"));
ace.config.setModuleLoader("ace/snippets/d", () => import("./src-noconflict/snippets/d.js"));
ace.config.setModuleLoader("ace/snippets/dart", () => import("./src-noconflict/snippets/dart.js"));
ace.config.setModuleLoader("ace/snippets/diff", () => import("./src-noconflict/snippets/diff.js"));
ace.config.setModuleLoader("ace/snippets/django", () => import("./src-noconflict/snippets/django.js"));
ace.config.setModuleLoader("ace/snippets/dockerfile", () => import("./src-noconflict/snippets/dockerfile.js"));
ace.config.setModuleLoader("ace/snippets/dot", () => import("./src-noconflict/snippets/dot.js"));
ace.config.setModuleLoader("ace/snippets/drools", () => import("./src-noconflict/snippets/drools.js"));
ace.config.setModuleLoader("ace/snippets/edifact", () => import("./src-noconflict/snippets/edifact.js"));
ace.config.setModuleLoader("ace/snippets/eiffel", () => import("./src-noconflict/snippets/eiffel.js"));
ace.config.setModuleLoader("ace/snippets/ejs", () => import("./src-noconflict/snippets/ejs.js"));
ace.config.setModuleLoader("ace/snippets/elixir", () => import("./src-noconflict/snippets/elixir.js"));
ace.config.setModuleLoader("ace/snippets/elm", () => import("./src-noconflict/snippets/elm.js"));
ace.config.setModuleLoader("ace/snippets/erlang", () => import("./src-noconflict/snippets/erlang.js"));
ace.config.setModuleLoader("ace/snippets/forth", () => import("./src-noconflict/snippets/forth.js"));
ace.config.setModuleLoader("ace/snippets/fortran", () => import("./src-noconflict/snippets/fortran.js"));
ace.config.setModuleLoader("ace/snippets/fsharp", () => import("./src-noconflict/snippets/fsharp.js"));
ace.config.setModuleLoader("ace/snippets/fsl", () => import("./src-noconflict/snippets/fsl.js"));
ace.config.setModuleLoader("ace/snippets/ftl", () => import("./src-noconflict/snippets/ftl.js"));
ace.config.setModuleLoader("ace/snippets/gcode", () => import("./src-noconflict/snippets/gcode.js"));
ace.config.setModuleLoader("ace/snippets/gherkin", () => import("./src-noconflict/snippets/gherkin.js"));
ace.config.setModuleLoader("ace/snippets/gitignore", () => import("./src-noconflict/snippets/gitignore.js"));
ace.config.setModuleLoader("ace/snippets/glsl", () => import("./src-noconflict/snippets/glsl.js"));
ace.config.setModuleLoader("ace/snippets/gobstones", () => import("./src-noconflict/snippets/gobstones.js"));
ace.config.setModuleLoader("ace/snippets/golang", () => import("./src-noconflict/snippets/golang.js"));
ace.config.setModuleLoader("ace/snippets/graphqlschema", () => import("./src-noconflict/snippets/graphqlschema.js"));
ace.config.setModuleLoader("ace/snippets/groovy", () => import("./src-noconflict/snippets/groovy.js"));
ace.config.setModuleLoader("ace/snippets/haml", () => import("./src-noconflict/snippets/haml.js"));
ace.config.setModuleLoader("ace/snippets/handlebars", () => import("./src-noconflict/snippets/handlebars.js"));
ace.config.setModuleLoader("ace/snippets/haskell", () => import("./src-noconflict/snippets/haskell.js"));
ace.config.setModuleLoader("ace/snippets/haskell_cabal", () => import("./src-noconflict/snippets/haskell_cabal.js"));
ace.config.setModuleLoader("ace/snippets/haxe", () => import("./src-noconflict/snippets/haxe.js"));
ace.config.setModuleLoader("ace/snippets/hjson", () => import("./src-noconflict/snippets/hjson.js"));
ace.config.setModuleLoader("ace/snippets/html", () => import("./src-noconflict/snippets/html.js"));
ace.config.setModuleLoader("ace/snippets/html_elixir", () => import("./src-noconflict/snippets/html_elixir.js"));
ace.config.setModuleLoader("ace/snippets/html_ruby", () => import("./src-noconflict/snippets/html_ruby.js"));
ace.config.setModuleLoader("ace/snippets/ini", () => import("./src-noconflict/snippets/ini.js"));
ace.config.setModuleLoader("ace/snippets/io", () => import("./src-noconflict/snippets/io.js"));
ace.config.setModuleLoader("ace/snippets/ion", () => import("./src-noconflict/snippets/ion.js"));
ace.config.setModuleLoader("ace/snippets/jack", () => import("./src-noconflict/snippets/jack.js"));
ace.config.setModuleLoader("ace/snippets/jade", () => import("./src-noconflict/snippets/jade.js"));
ace.config.setModuleLoader("ace/snippets/java", () => import("./src-noconflict/snippets/java.js"));
ace.config.setModuleLoader("ace/snippets/javascript", () => import("./src-noconflict/snippets/javascript.js"));
ace.config.setModuleLoader("ace/snippets/jexl", () => import("./src-noconflict/snippets/jexl.js"));
ace.config.setModuleLoader("ace/snippets/json", () => import("./src-noconflict/snippets/json.js"));
ace.config.setModuleLoader("ace/snippets/json5", () => import("./src-noconflict/snippets/json5.js"));
ace.config.setModuleLoader("ace/snippets/jsoniq", () => import("./src-noconflict/snippets/jsoniq.js"));
ace.config.setModuleLoader("ace/snippets/jsp", () => import("./src-noconflict/snippets/jsp.js"));
ace.config.setModuleLoader("ace/snippets/jssm", () => import("./src-noconflict/snippets/jssm.js"));
ace.config.setModuleLoader("ace/snippets/jsx", () => import("./src-noconflict/snippets/jsx.js"));
ace.config.setModuleLoader("ace/snippets/julia", () => import("./src-noconflict/snippets/julia.js"));
ace.config.setModuleLoader("ace/snippets/kotlin", () => import("./src-noconflict/snippets/kotlin.js"));
ace.config.setModuleLoader("ace/snippets/latex", () => import("./src-noconflict/snippets/latex.js"));
ace.config.setModuleLoader("ace/snippets/latte", () => import("./src-noconflict/snippets/latte.js"));
ace.config.setModuleLoader("ace/snippets/less", () => import("./src-noconflict/snippets/less.js"));
ace.config.setModuleLoader("ace/snippets/liquid", () => import("./src-noconflict/snippets/liquid.js"));
ace.config.setModuleLoader("ace/snippets/lisp", () => import("./src-noconflict/snippets/lisp.js"));
ace.config.setModuleLoader("ace/snippets/livescript", () => import("./src-noconflict/snippets/livescript.js"));
ace.config.setModuleLoader("ace/snippets/logiql", () => import("./src-noconflict/snippets/logiql.js"));
ace.config.setModuleLoader("ace/snippets/logtalk", () => import("./src-noconflict/snippets/logtalk.js"));
ace.config.setModuleLoader("ace/snippets/lsl", () => import("./src-noconflict/snippets/lsl.js"));
ace.config.setModuleLoader("ace/snippets/lua", () => import("./src-noconflict/snippets/lua.js"));
ace.config.setModuleLoader("ace/snippets/luapage", () => import("./src-noconflict/snippets/luapage.js"));
ace.config.setModuleLoader("ace/snippets/lucene", () => import("./src-noconflict/snippets/lucene.js"));
ace.config.setModuleLoader("ace/snippets/makefile", () => import("./src-noconflict/snippets/makefile.js"));
ace.config.setModuleLoader("ace/snippets/markdown", () => import("./src-noconflict/snippets/markdown.js"));
ace.config.setModuleLoader("ace/snippets/mask", () => import("./src-noconflict/snippets/mask.js"));
ace.config.setModuleLoader("ace/snippets/matlab", () => import("./src-noconflict/snippets/matlab.js"));
ace.config.setModuleLoader("ace/snippets/maze", () => import("./src-noconflict/snippets/maze.js"));
ace.config.setModuleLoader("ace/snippets/mediawiki", () => import("./src-noconflict/snippets/mediawiki.js"));
ace.config.setModuleLoader("ace/snippets/mel", () => import("./src-noconflict/snippets/mel.js"));
ace.config.setModuleLoader("ace/snippets/mips", () => import("./src-noconflict/snippets/mips.js"));
ace.config.setModuleLoader("ace/snippets/mixal", () => import("./src-noconflict/snippets/mixal.js"));
ace.config.setModuleLoader("ace/snippets/mushcode", () => import("./src-noconflict/snippets/mushcode.js"));
ace.config.setModuleLoader("ace/snippets/mysql", () => import("./src-noconflict/snippets/mysql.js"));
ace.config.setModuleLoader("ace/snippets/nginx", () => import("./src-noconflict/snippets/nginx.js"));
ace.config.setModuleLoader("ace/snippets/nim", () => import("./src-noconflict/snippets/nim.js"));
ace.config.setModuleLoader("ace/snippets/nix", () => import("./src-noconflict/snippets/nix.js"));
ace.config.setModuleLoader("ace/snippets/nsis", () => import("./src-noconflict/snippets/nsis.js"));
ace.config.setModuleLoader("ace/snippets/nunjucks", () => import("./src-noconflict/snippets/nunjucks.js"));
ace.config.setModuleLoader("ace/snippets/objectivec", () => import("./src-noconflict/snippets/objectivec.js"));
ace.config.setModuleLoader("ace/snippets/ocaml", () => import("./src-noconflict/snippets/ocaml.js"));
ace.config.setModuleLoader("ace/snippets/odin", () => import("./src-noconflict/snippets/odin.js"));
ace.config.setModuleLoader("ace/snippets/partiql", () => import("./src-noconflict/snippets/partiql.js"));
ace.config.setModuleLoader("ace/snippets/pascal", () => import("./src-noconflict/snippets/pascal.js"));
ace.config.setModuleLoader("ace/snippets/perl", () => import("./src-noconflict/snippets/perl.js"));
ace.config.setModuleLoader("ace/snippets/pgsql", () => import("./src-noconflict/snippets/pgsql.js"));
ace.config.setModuleLoader("ace/snippets/php", () => import("./src-noconflict/snippets/php.js"));
ace.config.setModuleLoader("ace/snippets/php_laravel_blade", () => import("./src-noconflict/snippets/php_laravel_blade.js"));
ace.config.setModuleLoader("ace/snippets/pig", () => import("./src-noconflict/snippets/pig.js"));
ace.config.setModuleLoader("ace/snippets/plain_text", () => import("./src-noconflict/snippets/plain_text.js"));
ace.config.setModuleLoader("ace/snippets/plsql", () => import("./src-noconflict/snippets/plsql.js"));
ace.config.setModuleLoader("ace/snippets/powershell", () => import("./src-noconflict/snippets/powershell.js"));
ace.config.setModuleLoader("ace/snippets/praat", () => import("./src-noconflict/snippets/praat.js"));
ace.config.setModuleLoader("ace/snippets/prisma", () => import("./src-noconflict/snippets/prisma.js"));
ace.config.setModuleLoader("ace/snippets/prolog", () => import("./src-noconflict/snippets/prolog.js"));
ace.config.setModuleLoader("ace/snippets/properties", () => import("./src-noconflict/snippets/properties.js"));
ace.config.setModuleLoader("ace/snippets/protobuf", () => import("./src-noconflict/snippets/protobuf.js"));
ace.config.setModuleLoader("ace/snippets/prql", () => import("./src-noconflict/snippets/prql.js"));
ace.config.setModuleLoader("ace/snippets/puppet", () => import("./src-noconflict/snippets/puppet.js"));
ace.config.setModuleLoader("ace/snippets/python", () => import("./src-noconflict/snippets/python.js"));
ace.config.setModuleLoader("ace/snippets/qml", () => import("./src-noconflict/snippets/qml.js"));
ace.config.setModuleLoader("ace/snippets/r", () => import("./src-noconflict/snippets/r.js"));
ace.config.setModuleLoader("ace/snippets/raku", () => import("./src-noconflict/snippets/raku.js"));
ace.config.setModuleLoader("ace/snippets/razor", () => import("./src-noconflict/snippets/razor.js"));
ace.config.setModuleLoader("ace/snippets/rdoc", () => import("./src-noconflict/snippets/rdoc.js"));
ace.config.setModuleLoader("ace/snippets/red", () => import("./src-noconflict/snippets/red.js"));
ace.config.setModuleLoader("ace/snippets/redshift", () => import("./src-noconflict/snippets/redshift.js"));
ace.config.setModuleLoader("ace/snippets/rhtml", () => import("./src-noconflict/snippets/rhtml.js"));
ace.config.setModuleLoader("ace/snippets/robot", () => import("./src-noconflict/snippets/robot.js"));
ace.config.setModuleLoader("ace/snippets/rst", () => import("./src-noconflict/snippets/rst.js"));
ace.config.setModuleLoader("ace/snippets/ruby", () => import("./src-noconflict/snippets/ruby.js"));
ace.config.setModuleLoader("ace/snippets/rust", () => import("./src-noconflict/snippets/rust.js"));
ace.config.setModuleLoader("ace/snippets/sac", () => import("./src-noconflict/snippets/sac.js"));
ace.config.setModuleLoader("ace/snippets/sass", () => import("./src-noconflict/snippets/sass.js"));
ace.config.setModuleLoader("ace/snippets/scad", () => import("./src-noconflict/snippets/scad.js"));
ace.config.setModuleLoader("ace/snippets/scala", () => import("./src-noconflict/snippets/scala.js"));
ace.config.setModuleLoader("ace/snippets/scheme", () => import("./src-noconflict/snippets/scheme.js"));
ace.config.setModuleLoader("ace/snippets/scrypt", () => import("./src-noconflict/snippets/scrypt.js"));
ace.config.setModuleLoader("ace/snippets/scss", () => import("./src-noconflict/snippets/scss.js"));
ace.config.setModuleLoader("ace/snippets/sh", () => import("./src-noconflict/snippets/sh.js"));
ace.config.setModuleLoader("ace/snippets/sjs", () => import("./src-noconflict/snippets/sjs.js"));
ace.config.setModuleLoader("ace/snippets/slim", () => import("./src-noconflict/snippets/slim.js"));
ace.config.setModuleLoader("ace/snippets/smarty", () => import("./src-noconflict/snippets/smarty.js"));
ace.config.setModuleLoader("ace/snippets/smithy", () => import("./src-noconflict/snippets/smithy.js"));
ace.config.setModuleLoader("ace/snippets/snippets", () => import("./src-noconflict/snippets/snippets.js"));
ace.config.setModuleLoader("ace/snippets/soy_template", () => import("./src-noconflict/snippets/soy_template.js"));
ace.config.setModuleLoader("ace/snippets/space", () => import("./src-noconflict/snippets/space.js"));
ace.config.setModuleLoader("ace/snippets/sparql", () => import("./src-noconflict/snippets/sparql.js"));
ace.config.setModuleLoader("ace/snippets/sql", () => import("./src-noconflict/snippets/sql.js"));
ace.config.setModuleLoader("ace/snippets/sqlserver", () => import("./src-noconflict/snippets/sqlserver.js"));
ace.config.setModuleLoader("ace/snippets/stylus", () => import("./src-noconflict/snippets/stylus.js"));
ace.config.setModuleLoader("ace/snippets/svg", () => import("./src-noconflict/snippets/svg.js"));
ace.config.setModuleLoader("ace/snippets/swift", () => import("./src-noconflict/snippets/swift.js"));
ace.config.setModuleLoader("ace/snippets/tcl", () => import("./src-noconflict/snippets/tcl.js"));
ace.config.setModuleLoader("ace/snippets/terraform", () => import("./src-noconflict/snippets/terraform.js"));
ace.config.setModuleLoader("ace/snippets/tex", () => import("./src-noconflict/snippets/tex.js"));
ace.config.setModuleLoader("ace/snippets/text", () => import("./src-noconflict/snippets/text.js"));
ace.config.setModuleLoader("ace/snippets/textile", () => import("./src-noconflict/snippets/textile.js"));
ace.config.setModuleLoader("ace/snippets/toml", () => import("./src-noconflict/snippets/toml.js"));
ace.config.setModuleLoader("ace/snippets/tsx", () => import("./src-noconflict/snippets/tsx.js"));
ace.config.setModuleLoader("ace/snippets/turtle", () => import("./src-noconflict/snippets/turtle.js"));
ace.config.setModuleLoader("ace/snippets/twig", () => import("./src-noconflict/snippets/twig.js"));
ace.config.setModuleLoader("ace/snippets/typescript", () => import("./src-noconflict/snippets/typescript.js"));
ace.config.setModuleLoader("ace/snippets/vala", () => import("./src-noconflict/snippets/vala.js"));
ace.config.setModuleLoader("ace/snippets/vbscript", () => import("./src-noconflict/snippets/vbscript.js"));
ace.config.setModuleLoader("ace/snippets/velocity", () => import("./src-noconflict/snippets/velocity.js"));
ace.config.setModuleLoader("ace/snippets/verilog", () => import("./src-noconflict/snippets/verilog.js"));
ace.config.setModuleLoader("ace/snippets/vhdl", () => import("./src-noconflict/snippets/vhdl.js"));
ace.config.setModuleLoader("ace/snippets/visualforce", () => import("./src-noconflict/snippets/visualforce.js"));
ace.config.setModuleLoader("ace/snippets/wollok", () => import("./src-noconflict/snippets/wollok.js"));
ace.config.setModuleLoader("ace/snippets/xml", () => import("./src-noconflict/snippets/xml.js"));
ace.config.setModuleLoader("ace/snippets/xquery", () => import("./src-noconflict/snippets/xquery.js"));
ace.config.setModuleLoader("ace/snippets/yaml", () => import("./src-noconflict/snippets/yaml.js"));
ace.config.setModuleLoader("ace/snippets/zeek", () => import("./src-noconflict/snippets/zeek.js"));
export default {};